import React from 'react';
import './member-card.css';

// assets
import ProfileEmpty from '../../assets/images/profile-empty.png';
import downIcon from '../../assets/illus-icons/down-icon.svg';

class MemberCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: false,
      memName: this.props.memName,
      memRole: this.props.memRole,
      memStay: this.props.memStay,
      memSummary: this.props.memSummary,
    };

    this.controlMenu = this.controlMenu.bind(this);
  }

  controlMenu() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }

  render() {
    return (
      <div className="memberCard">
        <div className="memberCard__person">
          <img src={ProfileEmpty} alt="profile" />
          <div className="memberCard__person__caption">
            <h4 className="memberCard__person__name">{this.state.memName}</h4>
            <h4 className="memberCard__person__role">{this.state.memRole}</h4>
            <h4 className="memberCard__person__stay">{this.state.memStay}</h4>
          </div>
        </div>
        <div className="memberCard__more">
          <button onClick={this.controlMenu}>
            <div className="memberCard__more__button">
              {this.state.isToggleOn ?
                <>
                  <p>View Less</p>
                  <img style={{transform: 'rotate(180deg'}} src={downIcon} alt="arrow-down" />
                </>
                :
                <>
                  <p>View More</p>
                  <img src={downIcon} alt="arrow-down" />
                </>
              }
            </div>
          </button>
          {this.state.isToggleOn ?
            <p className="memberCard__more__text">
              {this.state.memSummary}
            </p>
            :
            <></>
          }
        </div>
      </div>
    );
  }
}

export default MemberCard;
import React from 'react';
import './project-details.css';

// components
import Header from '../../../components/header/header.js';
import Footer from '../../../components/footer/footer.js';
// import VolunteerBanner from '../../../components/volunteer-banner/volunteer-banner.js';

// assets
// import MainImage from '../../../assets/images/project-detail-overlay.png';
// import showBigImage from '../../../assets/images/show-image-big.png';
// import showDoubleImage from '../../../assets/images/show-image-double.png';

// import downloadIcon from '../../../assets/illus-icons/download-cloud.svg';
// import colorLine from '../../../assets/illus-icons/color-line.svg';

function Project3() {
  return (
    <div className="mainContainer">
      <Header />

      <div className="pd__herosection">
        {/* other button */}
        <div className="pd__herosection__caption">
          <h1>Advocacy on the Rights of Domestic Workers (2009)</h1>
          <p>
            It is difficult to understand the work conditions of domestic workers to advocate on their behalf as they often work in the privacy of homes. To gain a clear picture of their working conditions, and to help break the barrier of silence surrounding domestic work, LAWA-Ghana launched the “Advocacy on the Rights of Domestic Workers Project” in October 2009. 
            <br /> <br />
            This project aimed at researching and reporting on the conditions of domestic workers. It sensitized employers to allow LAWA-Ghana project officers to enter their homes and monitor domestic workers for three months. The research was intended to support LAWA-Ghana’s advocacy efforts on behalf of all domestic workers.
          </p>
        </div>
        {/* <img src={MainImage} alt="hero-item" /> */}
      </div>

      {/* <div className="summary">
        <div className="summary__item1">
          <h3 className="summary__title">Team</h3>
          <ul>
            <li className="summary__text">blank space 1</li>
            <li className="summary__text">blank space 2</li>
            <li className="summary__text">blank space 3</li>
            <li className="summary__text">blank space 4</li>
          </ul>
        </div>
        <div className="summary__item2">
          <h3 className="summary__title">Deliverables</h3>
          <div className="deliverable__button">
            <p>download report</p>
            <img src={downloadIcon} alt="icon" />
          </div>
        </div>
        <div className="summary__item3">
          <h3 className="summary__title">Duration</h3>
          <p className="summary__text">blank space</p>
        </div>
        <div className="summary__item4">
          <div className="summary__item4 summary__item4__1">
            <h3 className="summary__title">Start Date</h3>
            <p className="summary__text">blank space</p>
          </div>
          <div className="summary__item4 summary__item4__2">
            <h3 className="summary__title">End Date</h3>
            <p className="summary__text">blank space</p>
          </div>
        </div>
      </div> */}

      <div className="brief">
        {/* <img src={colorLine} alt="colorline" /> */}
        <div className="brief__top">
          <h1>Project Summary</h1>
          <p>LAWA-Ghana recruited 80 domestic workers and their employers, mostly from the central and eastern regions of Ghana. Once every month, the LAWA project officer or local support person monitored the domestic worker by visiting them in the home to collect information both from journals provided to the domestic worker as well as using monitoring forms developed by LAWA. Furthermore, LAWA-Ghana provided employment contracts to all the participating employers and domestic workers to formalize their contracts. Finally, LAWA-Ghana organized training for domestic workers who participated in the project.</p>
        </div>
        <div className="brief__bottom">
          <h1>Achievement</h1>
          <p>During the project, 80 domestic workers were monitored, received training, and provided employment contracts. The project identified most of the challenges DWs faced. The findings support earlier research on the subject. The project allowed LAWA-Ghana to form partnerships with domestic workers from the eastern region. The project, furthermore, allowed LAWA-Ghana to provide information to and connect with more than 500 workers and employers about workers’ rights and issues.</p>
        </div>
      </div>

      {/* <div className="show__big">
        <img className="show__big__img" src={showBigImage} alt="project-detail" />
        <img className="show__big__line" src={colorLine} alt="color-line" />
        <p className="show__big__desc">blank space here -- image caption</p>
      </div> */}

      {/* <div className="show__double">
        <img src={showDoubleImage} alt="project-detail-double" />
        <img src={showDoubleImage} alt="project-detail-double" />
      </div> */}

      {/* <div className="uniqueness">
        <p>blank text here</p>
      </div> */}

      {/* <VolunteerBanner /> */}

      <Footer />
    </div>
  );
}

export default Project3;

import React from 'react';
// import { Link } from 'react-router-dom';
import './home.css';

// components
import Header from '../../components/header/header.js';
import Footer from '../../components/footer/footer.js';
import Values from '../../components/values/values.js';
// import VolunteerBanner from '../../components/volunteer-banner/volunteer-banner.js';

// assets
import heroImage from '../../assets/images/home-hero-image.png';
import socialImage1 from '../../assets/images/social-image-1.png';
import socialImage2 from '../../assets/images/social-image-2.png';

import commaIcon from '../../assets/illus-icons/comma-icon.svg';
import colorLine from '../../assets/illus-icons/color-line.svg';
// import viewArrow from '../../assets/illus-icons/view-arrow.svg';

import grad1 from '../../assets/illus-icons/grad-1.svg';
import grad5 from '../../assets/illus-icons/grad-5.svg';
import grad6 from '../../assets/illus-icons/grad-6.svg';

import primaryIcon1 from '../../assets/illus-icons/primary-goals-1.svg';
import primaryIcon2 from '../../assets/illus-icons/primary-goals-2.svg';
import primaryIcon3 from '../../assets/illus-icons/primary-goals-3.svg';
import primaryIcon4 from '../../assets/illus-icons/primary-goals-4.svg';
import primaryIcon5 from '../../assets/illus-icons/primary-goals-5.svg';

function Home() {
  return (
    <div className="mainContainer">

      <Header />

      {/* HERO SECTION */}
      <div className="hm__heroSection">
        <div className="hm__heroSection__image">
          <div className="hm__heroSection__image__overlay"></div>
          <img src={heroImage} alt='meeting' />
        </div>
        <div className="hm__heroSection__content__container">
          <div className='hm__heroSection__content__container__items'>
            <p>Promoting Public Policy on the Rights of Women and Children In Ghana and Africa.</p>
          </div>
        </div>
      </div>

      {/* SECTION 1 */}
      <div className="section1">
        <div className="section1__box">
          <img src={socialImage1} alt='social connection' />
        </div>
        <div className="section1__box">
          <img src={socialImage2} alt='social connection' />
        </div>
        <div className="section1__box2">
          <div className="section1__box2__content">
            <p className="section1__box2__description">
              “To deny people their human rights is to challenge their very humanity”
            </p>
            <p className="section1__box2__autor">
              - Nelson Mandella
            </p>
          </div>
          <img className="section1__box2__img1" src={grad1} alt='grad1' />
          <img className="section1__box2__img2" src={commaIcon} alt='comma-symbol' />
        </div>
      </div>

      {/* SECTION 3 */}
      <div className="section3">
        <p className="section3__mainText">
          LAWA (Ghana) Alumnae Incorporated (LAWA-Ghana) is a non-governmental organization made up of female lawyers who advocate on policy issues affecting the rights of women and children.
        </p>
      </div>

      <Values />

      {/* SECTION 5 */}
      <div className="section5">
        <img className="section5__image1" src={grad5} alt='grad5' />
        <img className="section5__image2" src={grad6} alt='grad6' />
        <div className="section5__heading">
          <h2 className="section5__heading__title">We are expanding to see our vision fulfilled.</h2>
          <div className="section5__heading__subtitle">
            A brief overview of what we've accomplished in the past few years.
          </div>
        </div>
        <div className="section5__content">
          <img src={colorLine} alt="color-line" />
          <div className='make-element-horiontal'>
            <div className="section5__content__top">
              <div className="section5__content__box">
                <div className="section5__content__box__content">
                  <h3 className="section5__content__box__content__title">15</h3>
                  <p className="section5__content__box__content__description">Major activities that have been undertaken in the past few years.</p>
                </div>
              </div>
              <div className="section5__content__box">
                <div className="section5__content__box__content">
                  <h3 className="section5__content__box__content__title">300</h3>
                  <p className="section5__content__box__content__description">Women sensitized in the year 2021 only.</p>
                </div>
              </div>
            </div>
            <div className="section5__content__bottom">
              <div className="section5__content__box">
                <div className="section5__content__box__content">
                  <h3 className="section5__content__box__content__title">3</h3>
                  <p className="section5__content__box__content__description">Main areas covered so far: Education, Research and publication.</p>
                </div>
              </div>
            </div>
          </div>
          <img src={colorLine} alt="color-line" />
        </div> 
      </div>

      {/* SECTION 6 */}
      <div className="section6">
        <div className="section6__heading">
          <div className="section6__heading__top">
            <h2 className="section6__heading__top__title">We Are On A Journey to Empower People</h2>
            <p className="section6__heading__top__subtitle">
              Our primary goals as an organization that moves us towards our vision.
            </p>
          </div>
          {/* <div className="section6__heading__bottom">
            <Link to="/about">
              <div className="section6__heading__bottom__link">
                <a href="./">View more about us</a>
                <img src={viewArrow} alt="view-arrow" />
              </div>
            </Link>
          </div> */}
        </div>
        <div className="section6__content">
          <div class="section6__content__item">
            <img src={primaryIcon1} alt="icons-illustration" />
            <p className="section6__content__item__text">Running legal literacy and awareness programs.</p>
          </div>
          <div class="section6__content__item">
            <img src={primaryIcon2} alt="icons-illustration" />
            <p className="section6__content__item__text">Conducting research into human rights, gender and development issues.</p>
          </div>
          <div class="section6__content__item">
            <img src={primaryIcon3} alt="icons-illustration" />
            <p className="section6__content__item__text">Advocating and lobbying for the protection of women and children's human rights.</p>
          </div>
          <div class="section6__content__item">
            <img src={primaryIcon4} alt="icons-illustration" />
            <p className="section6__content__item__text">Monitoring the implementation of International Human Rights Instruments.</p>
          </div>
          <div class="section6__content__item">
            <img src={primaryIcon5} alt="icons-illustration" />
            <p className="section6__content__item__text">Conducting litigation human rights.</p>
          </div>
        </div>
      </div>

      {/* <VolunteerBanner /> */}

      <Footer />
    </div>
  );
}

export default Home;

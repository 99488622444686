import React from 'react';
import { Link } from 'react-router-dom';
import './project-home.css';

// component
import Header from '../../../components/header/header.js';
import Footer from '../../../components/footer/footer.js';
// import VolunteerBanner from '../../../components/volunteer-banner/volunteer-banner.js';

// assets
import ProjectsHero from '../../../assets/images/projects-hero.png';
import Project1Cover from '../../../assets/images/project1-cover.png';
import Project2Cover from '../../../assets/images/project2-cover.png';
import Project3Cover from '../../../assets/images/project3-cover.png';

function ProjectHome() {
  return (
    <div className="mainContainer">
      <Header />

      <div className="ph__heroSection">
        <img src={ProjectsHero} alt="hero-item" />
        <div className="ph__heroSection__content">
          <h3>We reach out to empower our world.</h3>
          <p>Project we have worked on in the past few years</p>
        </div>
      </div>

      <div className="projectsList">
        <div className="projectList__card">
          <Link to="/projects/project1">
            <img src={Project1Cover} alt="project-cover" />
            <div className="projectList__card__content">
              {/* <p className="projectList__card__content__date">date here--</p> */}
              {/* <p className="projectList__card__content__title">title here--</p> */}
              <p className="projectList__card__content__desc">LAWA-Ghana's Advocacy In Collaboration With The Ghana Bar Association's Women And Minor's Rights Committee (2016-2022)</p>
            </div>
          </Link>
        </div>
        <div className="projectList__card">
          <Link to="/projects/project2">
            <img src={Project2Cover} alt="project-cover" />
            <div className="projectList__card__content">
              {/* <p className="projectList__card__content__date">date here--</p> */}
              {/* <p className="projectList__card__content__title">title here--</p> */}
              <p className="projectList__card__content__desc">Partnership With Network For Women's Rights In Ghana (Netright) In A Project Titled Addressing Systemic Barriers To Enhance Gender Equality And Social Inclusion In Land Governance Sponsored By Star Ghana (September 2017 - March 2020)</p>
            </div>
          </Link>
        </div>
        <div className="projectList__card">
          <Link to="/projects/project3">
            <img src={Project3Cover} alt="project-cover" />
            <div className="projectList__card__content">
              {/* <p className="projectList__card__content__date">date here--</p> */}
              {/* <p className="projectList__card__content__title">title here--</p> */}
              <p className="projectList__card__content__desc">Advocacy on the Rights of Domestic Workers (2009)</p>
            </div>
          </Link>
        </div>
        <div className="projectList__card">
          <Link to="/projects/project4">
            <img src={Project3Cover} alt="project-cover" />
            <div className="projectList__card__content">
              {/* <p className="projectList__card__content__date">date here--</p> */}
              {/* <p className="projectList__card__content__title">title here--</p> */}
              <p className="projectList__card__content__desc">International Labour Organization (ILO) Convention on Decent Work for Domestic workers</p>
            </div>
          </Link>
        </div>
      </div>

      {/* <VolunteerBanner /> */}

      <Footer />
    </div>
  );
}

export default ProjectHome;

import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

// assets
import logo from '../../assets/logo.png';
import fbIcon from '../../assets/illus-icons/facebook.svg';
import twtIcon from '../../assets/illus-icons/twitter.svg';

function Footer() {
  return (
    <div className="footer">
      <div className="footer__top">

        <div className="footer__top__left">
          <div className="footer__top__left__logoContainer">
            <Link to="/">
              <img className="footer__top__left__logoContainer__img" src={logo} alt="logo" />
            </Link>
          </div>
          <p className="footer__top__left__companyDescription">
            Promoting Public Policy on the Rights of women and Children In Ghana and Africa.
          </p>
        </div>

        <div className="footer__bar__vertical"></div>

        <div className="footer__top__right">
          <div className="footer__top__right__navMenu">
            <ul className="footer__top__right__navMenu__navItems">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/projects">Projects</Link>
              </li>
              {/* <li>
                <Link to="/volunteer">Volunteer</Link>
              </li> */}
            </ul>
            {/* <ul className="footer__top__right__navMenu__navItems">
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul> */}
          </div>
          <div className="footer__top__right__socialIcons">
            <div className="socialContentContainer">
              <p className="footer__top__right__socialIcons__title">Follow us</p>
              <div className="footer__top__right__socialIcons__icons">
                <a href='https://web.facebook.com/lawaghana98/?ref=pages_you_manage' rel="noopener noreferrer" target="_blank">
                  <img src={fbIcon} alt="social-icon" />
                </a>
                <a href='https://twitter.com/lawaghana98?t=lhFiQx2FF2vcFNW3CMxObA&s=08' rel="noopener noreferrer" target="_blank">
                  <img src={twtIcon} alt="social-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="footer__bottom">
        <div className="footer__bar__horizontal"></div>
        <p className="footer__bottom__text">Copyright © 2022. LAWA Ghana. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;

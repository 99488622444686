import React from 'react';
import './values.css';

// assets
import emblem1 from '../../assets/illus-icons/emblem1.svg';
import emblem2 from '../../assets/illus-icons/emblem2.svg';
import emblem3 from '../../assets/illus-icons/emblem3.svg';
import visionImage from '../../assets/images/vision-image.png';
import missionImage from '../../assets/images/mission-image.png';
import valueImage from '../../assets/images/value-image.png';

function Values() {
  return (
    <div className="section4">
      <div className="section4__box">
        <img className="section4__box__image" src={visionImage} alt='value' />
        <div className="section4__box__content">
          <div className="section4__box__title1">
            <div className="section4__box__title__iconBox">
              <img src={emblem1} alt="See by Alice Design from NounProject.com" />
            </div>
            <h3 className="section4__box__title1__text"> Our Vision </h3>
          </div>
          <div className="section4__box__description">
            To create a just and equal Ghanaian society where the rights of women and children are upheld.
          </div>
        </div>
      </div>
      <div className="section4__box">
        <img className="section4__box__image" src={missionImage} alt='mission' />
        <div className="section4__box__content">
          <div className="section4__box__title2">
            <div className="section4__box__title__iconBox">
              <img src={emblem2} alt="emblem" />
            </div>
            <h3 className="section4__box__title2__text"> Our Mission </h3>
          </div>
          <div className="section4__box__description">
            To assist with the reform of laws and practices that discriminate against women and children in Ghana by promoting and advocating for 
            the enactment and enforcement of laws and policies that protects their human rights.
          </div>
        </div>
      </div>
      <div className="section4__box">
        <img className="section4__box__image" src={valueImage} alt='Values' />
        <div className="section4__box__content">
          <div className="section4__box__title3">
            <div className="section4__box__title__iconBox">
              <img src={emblem3} alt="emblem" />
            </div>
            <h3 className="section4__box__title3__text"> Our Values </h3>
          </div>
          <div className="section4__box__description">
            Respect for the fundamental human rights for all persons.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Values;

import React from 'react';
import './about.css';

// components
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer.js';
import Values from '../../components/values/values.js';
import VolunteerBanner from '../../components/volunteer-banner/volunteer-banner.js';
import MemberCard from '../../components/member-card/member-card';

// assets
import heroImage1 from '../../assets/images/about-hero-1.png';
import heroImage2 from '../../assets/images/about-hero-2.png';
import colorLine from '../../assets/illus-icons/color-line.svg';
import AccomImage from '../../assets/images/about-accom-1.png';

class About extends React.Component {
  render() {
    return (
      <div className="mainContainer aboutpage">
        <Header textColor="#262424" />

        {/* hero section */}
        <div className="ab__heroSection">
          <div className="ab__heroSection__caption">
            <p className="ab__heroSection__caption__text1">We are LAWA and we</p>
            <p className="ab__heroSection__caption__text2">Advocate, Educate & Serve</p>
          </div>

          <div className="ab__heroSection__images">
            <div className="ab__heroSection__images__container1">
              <img className="ab__heroSection__images1" src={heroImage1} alt="right-item" />
            </div>
            <div className="ab__heroSection__images__container2">
              <img className="ab__heroSection__images2" src={heroImage2} alt="left-item" />
            </div>
          </div>

          <div className="ab__heroSection__brief">
            <h3 className="ab__heroSection__brief__text">LAWA-Ghana was incorporated in 1998 by a group of female lawyers who participated in the Leadership and Advocacy for 
            Women in Africa (LAWA) program and the Women's Law and Public Policy Fellowship program at Georgetown University Law Centre in Washington D.C</h3>
            <img src={colorLine} alt="color-line" />
          </div>
        </div>

        {/* Accomplishments */}
        <div className="accomplishment">
          <img src={AccomImage} alt="AccomImage" />
          <div className="accomplishment__statbox">
            <div className="accomplishment__statbox__item">
              <h3 className="accomplishment__statbox__item__heading">15</h3>
              <p className="accomplishment__statbox__item__desc">Major activities that have been undertaken in the past few years.</p>
            </div>
            <div className="accomplishment__statbox__item">
              <h3 className="accomplishment__statbox__item__heading">300+</h3>
              <p className="accomplishment__statbox__item__desc">Women sensitized in the year 2021 only.</p>
            </div>
            <div className="accomplishment__statbox__item">
              <h3 className="accomplishment__statbox__item__heading">3</h3>
              <p className="accomplishment__statbox__item__desc">Main areas covered so far: <em>Education, Research and publication.</em></p>
            </div>
          </div>
        </div>

        <Values className="about__values" />

        {/* Organization history */}
        <div className="history">
          <h3 className="history__heading">A Brief History</h3>
          <p className="history__desc">
            LAWA (Ghana) Alumnae Incorporated (LAWA Ghana) is a nongovernmental organization focused on protecting and promoting the human rights of women and children in Ghana.
            LAWA-Ghana was incorporated in 1998 by a group of female lawyers who participated in the Leadership and Advocacy for Women in Africa (LAWA) program and the Women's Law and Public Policy Fellowship program at Georgetown University Law Centre in Washington D.C.
            LAWA is the acronym for Leadership and Advocacy for Women in Africa. This program selects women lawyers from Africa who are interested to participate in a Master of Law (L.L.M.) program at Georgetown University Law Centre and a seminar program conducted by the Women's law and Public Policy Fellowship Program (WLPPFP).
            During the sixteen-month program, members are assigned to work for six months with a legal or public interest organization in Washington D.C. on legal and policy issues affecting the status of women. The LAWA program has trained women lawyers from countries including Ghana, Tanzania, Uganda, South Africa and Sierra Leone.
            Upon their return to their home countries, the alumnae are expected to work towards improving human rights in their own countries. LAWA (Ghana) is comprised of alumnae of the LAWA program and is the vehicle through which the organization seeks to promote and protect women's rights in Ghana. LAWA (Ghana) focuses on research-based advocacy, lobbying for legislation to protect women's rights, awareness and education campaigns on women and children's rights, as well as human rights litigation and monitoring the implementation of human rights legislation. There are currently nine ordinary members of LAWA-Ghana who participated in the programs. LAWA-Ghana's membership also consists of associate and honorary members.
            The associate and honorary members consist of women with a background and interest in women and children development issues and may apply to become associate members.
            Honorary membership is conferred on outstanding persons who have supported the work of LAWA-Ghana.
          </p>
        </div>

        {/* Members of LAWA */}
        <div className="members">
          <div className="members__heading">
            <h3 className="members__heading__title">Meet the LAWA Ghana members</h3>
            <p className="members__heading__desc">Members of LAWA-Ghana is made up of Ghanaian alumnae of the GULC who participated in the  LAWA program. Currently there are nine ordinary members of LAWA-Ghana.</p>
          </div>

          <div className="responsive_grid">
            <MemberCard 
              memName="Sheila Minkah-Premo"
              memRole="Chairperson"
              memStay="1998 - Present"
              memSummary="Detailed member profile shows up here"
            />
            <MemberCard 
              memName="Fitnat Adjetey"
              memRole="Board member"
              memStay="1998 - Present"
              memSummary="Detailed member profile shows up here"
            />
            <MemberCard 
              memName="Beatrice Duncan"
              memRole="Member"
              memStay="1998 - Present"
              memSummary="Detailed member profile shows up here"
            />
            <MemberCard 
              memName="Angela Dwamena-Aboagye"
              memRole="Board Member"
              memStay="1998 - Present"
              memSummary="Detailed member profile shows up here"
            />
            <MemberCard 
              memName="Hilary Gbedemah"
              memRole="Board Member"
              memStay="1998 - Present"
              memSummary="Detailed member profile shows up here"
            />
            <MemberCard 
              memName="Barbara Ayesu"
              memRole="Board Secretary"
              memStay="1998 - Present"
              memSummary="Detailed member profile shows up here"
            />
            <MemberCard 
              memName="Gloria Ofori-Boadu"
              memRole="Member"
              memStay="1998 - Present"
              memSummary="Detailed member profile shows up here"
            />
            <MemberCard 
              memName="Bernice Sam"
              memRole="Member"
              memStay="1998 - Present"
              memSummary="Detailed member profile shows up here"
            />
            <MemberCard 
              memName="Jane Quaye"
              memRole="Member"
              memStay="1998 - Present"
              memSummary="Detailed member profile shows up here"
            />
          </div>
         
        </div>

        <VolunteerBanner />

        <Footer />
      </div>
    );
  }
}

export default About;

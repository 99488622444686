import React from 'react';
import './project-details.css';

// components
import Header from '../../../components/header/header.js';
import Footer from '../../../components/footer/footer.js';
// import VolunteerBanner from '../../../components/volunteer-banner/volunteer-banner.js';

// assets
import ProjectHero from '../../../assets/images/project2/project-hero.png';
import ProjectImage1Big from '../../../assets/images/project2/project-image-1-big.png';
import ProjectImage2Big from '../../../assets/images/project2/project-image-2-big.png';
import ProjectImage3Big from '../../../assets/images/project2/project-image-3-big.png';

function Project2() {
  return (
    <div className="mainContainer">
      <Header />

      <div className="pd__herosection">
        {/* other button */}
        <div className="pd__herosection__caption">
          <h1>Partnership With Network For Women's Rights In Ghana (Netright) In A Project Titled Addressing Systemic Barriers To Enhance Gender Equality And Social Inclusion In Land Governance Sponsored By Star Ghana (September 2017 - March 2020)</h1>
          <p>Access to land is a major difficulty for women farmers, even though it is a basic prerequisite for farming. This is due to structural and systematic challenges in Ghana's land tenure system, which have resulted in gender and class inequities. In the domain of spousal property rights, the female spouse is frequently denied her rights to jointly acquired land property, despite article 22 of the Republic of Ghana's 1992 Constitution, which requires equity in sharing such properties.</p>
        </div>
        <img src={ProjectHero} alt="hero-item" />
      </div>
      
      <div className="brief">
        <div className="brief__top">
          <h1>Project Summary</h1>
          <p>
            LAWA-Ghana collaborated with Netright (the lead organisation) on a 30-month programme called "Addressing Systemic Barriers to Enhance Gender Equality and Social Inclusion in Land Governance," which was funded by Star-Ghana through the Gender and Social Inclusion (GESI) Strategic Partnership Grant Award. The project's objectives were to identify and address gender-related problems associated with land governance to improve women's access, ownership, and control of land to improve their security of land tenure and livelihood, to ensure that the land governance system adequately protects women through the passage of the new Land Act, and to sensitize women and socially excluded persons about their land rights. Many women are unaware of their land rights. 
            <br /> <br />
            LAWA-Ghana's role in the project was to simplify the draft Land Bill and related guidelines and also serve as the legal experts and resource persons in disseminating information on the Bill and its guidelines. Mrs. Sheila Minkah-Premo, the chairperson of LAWA-Ghana, wrote a book titled "The simplification of the Land Bill, 2017, related laws, and land governance standards in Ghana handbook for workshops with key stakeholders." The manual aimed to summarize the Land Act, the Lands Commission Act, and the Lands Commission Guidelines for Large Scale Land Transactions in Ghana 2016 for Netright and LAWA-Ghana to have uniform materials to sensitise their constituents on these instruments to equip and guide advocacy in ensuring that their instruments effectively address Gender and Social Inclusion issues.
          </p>
        </div>
        <div className="brief__bottom">
          <h1>Achievement</h1>
          <p>During this project, LAWA and Netright have involved and sensitized National and Regional Stakeholders, Women’s Rights Organizations (WROs), smallholder farmers, socially excluded groups and their organizations and other land-related CSOs in each of the regions on laws and policies governing the land sector, GESI analysis and advocacy skills. The project has facilitated a process of networking and collective gendered policy interventions on land issues by Women Rights Organizations (WROs) through mobilizing and convening stakeholders’ meetings for joint action.</p>
        </div>
      </div>

      <div className="show__big">
        <img className="show__big__img" src={ProjectImage1Big} alt="project" />
      </div>

      <div className="show__big">
        <img className="show__big__img" src={ProjectImage2Big} alt="project" />
      </div>

      <div className="show__big">
        <img className="show__big__img" src={ProjectImage3Big} alt="project" />
      </div>

      {/* <VolunteerBanner /> */}

      <Footer />
    </div>
  );
}

export default Project2;

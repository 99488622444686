import React from 'react';
import './project-details.css';

// components
import Header from '../../../components/header/header.js';
import Footer from '../../../components/footer/footer.js';
// import VolunteerBanner from '../../../components/volunteer-banner/volunteer-banner.js';

// assets
import ProjectHero from '../../../assets/images/project1/project-hero.png';
import ProjectImage1Big from '../../../assets/images/project1/project-image-1-big.png';
import ProjectImage2Big from '../../../assets/images/project1/project-image-2-big.png';
import ProjectImage3Big from '../../../assets/images/project1/project-image-3-big.png';
import ProjectImage4Big from '../../../assets/images/project1/project-image-4-big.png';

function Project1() {
  return (
    <div className="mainContainer">
      <Header />

      <div className="pd__herosection">
        {/* other button */}
        <div className="pd__herosection__caption">
          <h1>LAWA-Ghana's Advocacy In Collaboration With The Ghana Bar Association's Women And Minor's Rights Committee (2016-2022)</h1>
          <p>Since 2016, LAWA-Ghana has worked with the Ghana Bar Association's Women and Minors Rights Committee (WMRC). WMRC is in charge of facilitating access to legal aid for women and children in collaboration with institutions that provide legal aid to women and minors, as well as working with the Ghana Bar Association's (GBA) Legal Aid Committee to improve access to justice for women and minors, particularly in cases involving domestic violence.</p>
        </div>
        <img src={ProjectHero} alt="hero-item" />
      </div>

      <div className="brief">
        {/* <img src={colorLine} alt="colorline" /> */}
        <div className="brief__top">
          <h1>Project Summary</h1>
          <p>The sensitization workshops aim to educate the public, particularly women and children, on the rights and responsibilities of children and juvenile justice, domestic violence and child custody, and women's property rights. Participants are sensitized to various issues as indicated above. Participants are allowed to ask clarifying questions and anything else that is bugging them about the subject.</p>
        </div>
        <div className="brief__bottom">
          <h1>Achievement</h1>
          <p>LAWA's advocacy, with the help of the WMRC, has reached around 5,000 people, with 90% of them being women and children. Participants include students and teachers from private and public schools, religious women's organisations, domestic workers and some sections of the general public. LAWA-Ghana's advocacy was carried out in the following districts: La-Nkwantang District, Ga East Municipal District, Akuapem South District, Ga Central District, and Ablekuma North District.</p>
        </div>
      </div>

      <div className="show__big">
        <img className="show__big__img" src={ProjectImage1Big} alt="project" />
      </div>

      <div className="show__big">
        <img className="show__big__img" src={ProjectImage2Big} alt="project" />
      </div>

      <div className="show__big">
        <img className="show__big__img" src={ProjectImage3Big} alt="project" />
      </div>

      <div className="show__big">
        <img className="show__big__img" src={ProjectImage4Big} alt="project" />
      </div>

      {/* <VolunteerBanner /> */}

      <Footer />
    </div>
  );
}

export default Project1;

import React, { useRef, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import './contact-us.css';

// components
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';

function ContactUs() {
  const ref = useRef(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const startSubmit = () => {
    // send message to firebase
    
    if (firstName && lastName && phoneNumber && email && message) {
      ref.current.continuousStart();

      setTimeout(() => {
        ref.current.complete();
      }, 900);
    };
  }

  return (
  <div className="mainContainer">
    <LoadingBar color='#2998ff' ref={ref} />

    <Header textColor="#262424" />

    <div className="contactInfo">
      <div className="contactInfo__item">
        <h3>Reach Us</h3>
        <p>+233 20 813 6809</p>
        <p>+233 24 509 1696</p>
        <p>
          <a href="mailto:lawaghana98@yahoo.com" className="contactInfo__item__email">lawaghana98@yahoo.com</a>
        </p>
      </div>
      <div className="contactInfo__item">
        <h3>Our Address</h3>
        <p>Ghana Post GPS: GM-085-9761</p>
        <p>P. O. Box GP 21048</p>
        <p>Pantang, Greater Accra, Ghana</p>
      </div>
    </div>

    <div className="mapForm">
      <div className="googlemapsContainer">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.0019215765233!2d-0.1909616852333609!3d5.712856995859424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb69a129350d3241b!2zNcKwNDInNDYuMyJOIDDCsDExJzE5LjYiVw!5e0!3m2!1sen!2sgh!4v1647663598805!5m2!1sen!2sgh"
          title="ourlocation"
          width="100%"
          height="100%"
          style={{border:0}}
          allowFullScreen=""
          loading="lazy"
        >
        </iframe>
      </div>

      <div className="formbox">
        <div className='formbox__heading'>
          <h1>Contact Us</h1>
          <p>Feel free to reach out to us for further information.</p>
        </div>
        <form action="https://formsubmit.co/a756c1280bd5d19f53515eda096ae27d" method="POST">
          <input type="hidden" name="_subject" value="Website New submission!!! - CONTACT US PAGE"></input>
          <input type="hidden" name="_captcha" value="false"></input>
          <input type="hidden" name="_template" value="table"></input>
          <input type="hidden" name="_next" value="https://lawa.netlify.app/thankyou"></input>
          <div className="formbox__formgroup">
            <input value={firstName} onChange={e => setFirstName(e.target.value)} type="text" name="First Name" className="formbox__formgroup__input" placeholder="First name" required />
            <input value={lastName} onChange={e => setLastName(e.target.value)} type="text" name="Last Name" className="formbox__formgroup__input" placeholder="Last name" required />
          </div>
          <div className="formbox__formgroup">
            <input value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} type="number" name="Phone Number" className="formbox__formgroup__input" placeholder="Phone number" />
            <input value={email} onChange={e => setEmail(e.target.value)} type="text" name="Email Address" className="formbox__formgroup__input" placeholder="Email address" />
          </div>
          <textarea value={message} onChange={e => setMessage(e.target.value)} rows="8" name="Message" placeholder="type your message here" />
          <button 
            type="submit"
            className="formbox__sendButton" 
            onClick={startSubmit}
          >
            Send message
          </button>
        </form>
      </div>
    </div>

    <Footer />

  </div>   
  );
}

export default ContactUs;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

// components
import ScrollToTop from './components/scroll-to-top.js';

// pages
import Home from './pages/home/home.js';
import About from './pages/about/about.js';
import ContactUs from './pages/contact-us/contact-us.js';
import Volunteer from './pages/volunteer/volunteer.js';
import ThankYou from './pages/thank-you/thank-you';
import ProjectHome from './pages/project/project-home/project-home.js';

// ---- projects
import Project1 from './pages/project/project-details/project-1.js';
import Project2 from './pages/project/project-details/project-2.js';
import Project3 from './pages/project/project-details/project-3.js';
import Project4 from './pages/project/project-details/project-4.js';

function App() {
  return (
    <div className="mainApp">
      <Router>
        <ScrollToTop>
          <div>
            <Routes>
              <Route exact path='/' element={<Home />}></Route>
              <Route path='/about' element={<About />}></Route>
              <Route path='/projects' element={<ProjectHome />}></Route>
              <Route path='/contact' element={<ContactUs />}></Route>
              <Route path='/volunteer' element={<Volunteer />}></Route>
              <Route path='/thankyou' element={<ThankYou />}></Route>

              {/* ---- projects details page links */}
              <Route path='/projects/project1' element={<Project1 />}></Route>
              <Route path='/projects/project2' element={<Project2 />}></Route>
              <Route path='/projects/project3' element={<Project3 />}></Route>
              <Route path='/projects/project4' element={<Project4 />}></Route>
            </Routes>
          </div>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom';
import './header.css';

// assets
import logo from '../../assets/logo.png';
import menuIcon from '../../assets/illus-icons/menu-icon.svg';
import closeIcon from '../../assets/illus-icons/close-icon.svg';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isToggleOn: false};

    this.controlMenu = this.controlMenu.bind(this);

    this.textColor = {
      color: this.props.textColor,
    }
  }

  controlMenu() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }

  render() {
    return (
      <div className="header">
  
        <div className="header__logoContainer">
          <Link to="/">
            <img className="header__logoContainer__logo" src={logo} alt="Logo" />
          </Link>
        </div>
  
        <div className="header__navMenu">
          <ul className="header__navItems">
            <li style={this.textColor}><Link to="/">Home</Link></li>
            <li style={this.textColor}><Link to="/projects">Projects</Link></li>
            {/* <li style={this.textColor}><Link to="/volunteer">Volunteer</Link></li> */}
            {/* <li style={this.textColor}><Link to="/about">About Us</Link></li> */}
            {/* <li style={this.textColor}><Link to="/contact">Contact Us</Link></li> */}
          </ul>
          <button onClick={this.controlMenu}>
            <img className="header__navMenu__icon" src={menuIcon} alt="menu-icon" />
          </button>
        </div>

        {this.state.isToggleOn ? 
          <div className="header__menuContainer">
            <div className="header__menuContainer__closebutton">
              <div onClick={this.controlMenu}>
                <p className="header__menuContainer__closebutton__text">Close menu</p>
                <img className="header__menuContainer__closebutton__img" src={closeIcon} alt="close-icon" />
              </div>
            </div>
            <div className="header__menuContainer__nav">
              <div className="header__menuContainer__nav__item">
                <p>
                  <Link to="/">Home</Link>
                </p>
              </div>
              <div className="header__menuContainer__nav__item">
                <p>
                  <Link to="/projects">Projects</Link>
                </p>
              </div>
              {/* <div className="header__menuContainer__nav__item">
                <p>
                  <Link to="/volunteer">Volunteer</Link>
                </p>
              </div> */}
              {/* <div className="header__menuContainer__nav__item">
                <p>
                  <Link to="/about">About Us</Link>
                </p>
              </div> */}
              <div className="header__menuContainer__nav__item">
                <p>
                  <Link to="/contact">Contact Us</Link>
                </p>
              </div>
            </div>
          </div> 
          : 
          <></>
        }
  
      </div>
    );
  }
}

export default Header;

import React from 'react';
import { Link } from 'react-router-dom';
import './volunteer-banner.css';

// assets
import volunMobile1 from '../../assets/images/volun-image-mobile-1.png';
import volunMobile2 from '../../assets/images/volun-image-mobile-2.png';
import volunDesktop1 from '../../assets/images/volun-image-desktop-1.png';
import volunDesktop2 from '../../assets/images/volun-image-desktop-2.png';

function VolunteerBanner() {
  return (
    <div className="testimonialContainer">
      <div className="testimonialContainer__content">
        <h2 className="testimonialContainer__content__title">Get Involved</h2>
        <p className="testimonialContainer__content__description">
          LAWA-Ghana's works has been supported by a number of development partners and you can also join us develop our society together.
        </p>
        <Link to="/volunteer">
          <div className="testimonialContainer__content__input">
            <button>Volunteer</button>
          </div>
        </Link>
      </div>
      <img src={volunMobile1} className="testimonialContainer__image1" alt="vounteer-illus" />
      <img src={volunMobile2} className="testimonialContainer__image2" alt="vounteer-illus" />

      <img src={volunDesktop1} className="testimonialContainer__image1__desktop" alt="vounteer-illus" />
      <img src={volunDesktop2} className="testimonialContainer__image2__desktop" alt="vounteer-illus" />
    </div>
  );
}

export default VolunteerBanner;

import React from 'react';
import './project-details.css';

// components
import Header from '../../../components/header/header.js';
import Footer from '../../../components/footer/footer.js';
// import VolunteerBanner from '../../../components/volunteer-banner/volunteer-banner.js';

// assets
// import MainImage from '../../../assets/images/project-detail-overlay.png';
// import showBigImage from '../../../assets/images/show-image-big.png';
// import showDoubleImage from '../../../assets/images/show-image-double.png';

// import downloadIcon from '../../../assets/illus-icons/download-cloud.svg';
// import colorLine from '../../../assets/illus-icons/color-line.svg';

function Project4() {
  return (
    <div className="mainContainer">
      <Header />

      <div className="pd__herosection">
        {/* other button */}
        <div className="pd__herosection__caption">
          <h1>International Labour Organization (ILO) Convention on Decent Work for Domestic workers.</h1>
        </div>
        {/* <img src={MainImage} alt="hero-item" /> */}
      </div>

      {/* <div className="summary">
        <div className="summary__item1">
          <h3 className="summary__title">Team</h3>
          <ul>
            <li className="summary__text">blank space 1</li>
            <li className="summary__text">blank space 2</li>
            <li className="summary__text">blank space 3</li>
            <li className="summary__text">blank space 4</li>
          </ul>
        </div>
        <div className="summary__item2">
          <h3 className="summary__title">Deliverables</h3>
          <div className="deliverable__button">
            <p>download report</p>
            <img src={downloadIcon} alt="icon" />
          </div>
        </div>
        <div className="summary__item3">
          <h3 className="summary__title">Duration</h3>
          <p className="summary__text">blank space</p>
        </div>
        <div className="summary__item4">
          <div className="summary__item4 summary__item4__1">
            <h3 className="summary__title">Start Date</h3>
            <p className="summary__text">blank space</p>
          </div>
          <div className="summary__item4 summary__item4__2">
            <h3 className="summary__title">End Date</h3>
            <p className="summary__text">blank space</p>
          </div>
        </div>
      </div> */}

      <div className="brief">
        {/* <img src={colorLine} alt="colorline" /> */}
        <div className="brief__top">
          {/* <h1>Project Summary</h1> */}
          <p>Recognizing the plight of domestic workers as a worldwide phenomenon, the ILO in 2008 added a draft convention on decent work for domestic workers to the agenda of the 99th International Labour Conference (ILC). The Ghanaian government sponsored LAWA-Ghana to attend the conference along with State representatives in Switzerland in 2008.</p>
        </div>
        <div className="brief__bottom">
          {/* <h1>Achievement</h1> */}
          <p>The conference offered an opportunity for LAWA-Ghana to network and share information with other organizations such as the International Domestic Workers Network (IDWN). This organization, of which LAWA-Ghana is now a member, is comprised of domestic workers’ unions and associations worldwide working to create useful networks and support groups for domestic workers. Together, organizations, governments and other stakeholders created a draft convention on decent work for domestic workers. </p>
        </div>
        <div className="brief__bottom">
          {/* <h1>Achievement</h1> */}
          <p>Passing the draft convention was then put on the agenda for the 100th ILC in 2011. In that same year on the 16th of June which was also the 100th anniversary of the ILC, the Domestic Workers Convention no 189 was adopted by governments, employers and workers worldwide.</p>
        </div>
        <div className="brief__bottom">
          {/* <h1>Achievement</h1> */}
          <p>The passing of this convention was a historic event for the rights of domestic workers. The convention was accompanied by recommendation 201 which suggests ways for member countries to effectively implement the convention. The convention covers many of the cross-cutting issues which affect nearly 100 million domestic workers worldwide. It touches on labour rights, child employment and abuse, working hours, contracts of employment, safety as well as human trafficking.</p>
        </div>
        <div className="brief__bottom">
          {/* <h1>Achievement</h1> */}
          <p>The convention also provides official definitions of domestic work and domestic workers. The recommendations encourage members to enact legislative and policy mechanisms and implement the provisions of the convention as well as related ILO conventions protecting workers from exploitation and abuse.</p>
        </div>
        <div className="brief__bottom">
          {/* <h1>Achievement</h1> */}
          <p>The passing of the Domestic Workers Convention no 189was a momentous event. The convention received broad support and goodwill messages from the majority of the participants at the conference. This support allowed the convention to be passed after only one year in existence.</p>
        </div>
        <div className="brief__bottom">
          {/* <h1>Achievement</h1> */}
          <p>Six countries had ratified the convention by the year 2013, and others had taken the initiative to enact laws specifically targeting domestic workers.</p>
        </div>
        <div className="brief__bottom">
          {/* <h1>Achievement</h1> */}
          <p>In light of the convention’s passing and the increasing ratifications, protecting the rights of domestic workers is a more pressing goal than ever.</p>
        </div>
      </div>

      {/* <div className="show__big">
        <img className="show__big__img" src={showBigImage} alt="project-detail" />
        <img className="show__big__line" src={colorLine} alt="color-line" />
        <p className="show__big__desc">blank space here -- image caption</p>
      </div> */}

      {/* <div className="show__double">
        <img src={showDoubleImage} alt="project-detail-double" />
        <img src={showDoubleImage} alt="project-detail-double" />
      </div> */}

      {/* <div className="uniqueness">
        <p>blank text here</p>
      </div> */}

      {/* <VolunteerBanner /> */}

      <Footer />
    </div>
  );
}

export default Project4;

import React from 'react';
import './thank-you.css';

function ThankYou () {
  return(
    <div className="thankyou-page">
      <div className="_header">
        <div className="logo">
          <img src="https://codexcourier.com/images/banner-logo.png" alt="" />
        </div>
        <h1>Thank You!</h1>
      </div>
      <div className="_body">
        <div className="_box">
          <h2>
            <strong>Please we have received your message,</strong> we will review it get back to you.
          </h2>
          <p>
            Thanks a bunch for filling that out. It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today. Thanks for being you.
          </p>
        </div>
      </div>
      <div className="_footer">
        <a className="btn" href="https://lawa.netlify.app/">Back to LAWA homepage</a>
        <p>Website designed and built with love <a href="https://www.fexmyweb.co/" target="_blank" rel="noopener noreferrer">@Fexmyweb Studio</a> </p>
      </div>
  </div>
  );
}

export default ThankYou;
import React, { useRef, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import './volunteer.css';

// components
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';

// assets
import volunteerImage from '../../assets/images/volunteer-image.png';

function Volunteer() {
  const ref = useRef(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const startSubmit = () => {
    if (firstName && lastName && phoneNumber && email && message) {
      ref.current.continuousStart();

      setTimeout(() => {
        ref.current.complete();
      }, 900);
    };
  }

  return (
  <div className="mainContainer volunteerPage">
    <LoadingBar color='#2998ff' ref={ref} />

    <Header textColor="#262424" />

    <div className="imgForm">
      <div className="volunteerImage">
        <img src={volunteerImage} alt="volunteerImage" />
      </div>

      <div className="formbox">
        <div className='formbox__heading'>
          <h1>Volunteer Now</h1>
          <p>We invite persons interested in becoming associate members who have a background in women's and development issues as we expand to see our vision fulfilled.</p>
        </div>
        <form action="https://formsubmit.co/a756c1280bd5d19f53515eda096ae27d" method="POST">
          <input type="hidden" name="_subject" value="Website New submission!!! - VOLUNTEER PAGE"></input>
          <input type="hidden" name="_captcha" value="false"></input>
          <input type="hidden" name="_template" value="table"></input>
          <input type="hidden" name="_next" value="https://lawa.netlify.app/thankyou"></input>
          <div className="formbox__formgroup">
            <input value={firstName} onChange={e => setFirstName(e.target.value)} type="text" name="First Name" className="formbox__formgroup__input" placeholder="First name" required />
            <input value={lastName} onChange={e => setLastName(e.target.value)} type="text" name="Last Name" className="formbox__formgroup__input" placeholder="Last name" required />
          </div>
          <div className="formbox__formgroup">
            <input value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} type="number" name="Phone Number" className="formbox__formgroup__input" placeholder="Phone number" />
            <input value={email} onChange={e => setEmail(e.target.value)} type="text" name="Email Address" className="formbox__formgroup__input" placeholder="Email address" />
          </div>
          <textarea value={message} onChange={e => setMessage(e.target.value)} rows="8" name="Message" placeholder="type your message here" />
          <button 
            type="submit"
            className="formbox__sendButton" 
            onClick={startSubmit}
          >
            Get started
          </button>
        </form>
      </div>
    </div>

    <Footer />

  </div>   
  );
}

export default Volunteer;
